import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IPoll } from '../../../../../lib-ui/src/shared/interfaces/poll';
import { IPollResponse } from '../../../../../lib-ui/src/shared/interfaces/poll-responses';
import { IResponse } from '../../../../../lib-ui/src/shared/interfaces/response';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PollService {
  private readonly URI_API = environment.URLAPI;

  constructor(private readonly httpClient: HttpClient) {}

  getPollById(pollId: string): Observable<IResponse<IPoll> | { data: IPoll; success: boolean; message: string; }> {
    return this.httpClient.get<IResponse<IPoll>>(
      `${this.URI_API}Poll/${pollId}`
    ).pipe(
      catchError((error) => {
        console.error('Error fetching poll by ID:', error);
        return of({ data: {} as IPoll, success: false, message: 'Error fetching poll by ID' });
      })
    );
  }

  sendPollResponse(
    pollResponse: IPollResponse
  ): Observable<IResponse<boolean> | { data: boolean; success: boolean; message: string; }> {
    return this.httpClient.post<IResponse<boolean>>(
      `${this.URI_API}PollSubmission`,
      pollResponse
    ).pipe(
      catchError((error) => {
        console.error('Error sending poll response:', error);
        return of({ data: false, success: false, message: 'Error sending poll response' });
      })
    );
  }
}
