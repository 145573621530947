<ng-container *ngIf="unitPoll.poll && !answered && !lockScreen; else unavailable">
  <lib-poll-manager
    [poll]="unitPoll.poll"
    [isNotUnityDevice]="true"
    (onFinishPoll)="onFinishPoll($event)"
  ></lib-poll-manager>
</ng-container>
<ng-template #unavailable>
  <app-page-unavailable></app-page-unavailable>
</ng-template>
