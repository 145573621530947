import { Routes } from '@angular/router';
import { PollWebComponent } from './poll-web/poll-web.component';
import { GetUnitPollResolver } from './shared/services/resolves/get-unit-poll.resolve';

export const routes: Routes = [
  {
    path: ':unitCode',
    title: 'Pesquisa',
    resolve: {
      unitPoll: GetUnitPollResolver,
    },
    loadComponent: () => PollWebComponent,
  },
];
