import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { IResponse } from '../../../../../../lib-ui/src/shared/interfaces/response';
import { IPartialUnit } from '../../../../../../lib-ui/src/shared/interfaces/unit';
import { UnitPollService } from '../unitPoll.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GetUnitPollResolver implements Resolve<IResponse<IPartialUnit | null>> {
  constructor(private unitPollService: UnitPollService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IResponse<IPartialUnit | null>> {
    return this.unitPollService.getUnitPoll(route.params['unitCode']).pipe(
      catchError((error) => {
        console.error('Error resolving unit poll:', error);
        return throwError(() => error);
      })
    );
  }
}