import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, take } from 'rxjs';
import { PageUnavailableComponent } from '../../../../lib-ui/src/shared/components/page-unavailable/page-unavailable.component';
import { PollManagerComponent } from '../../../../lib-ui/src/shared/components/poll-manager/poll-manager.component';
import {
  IPollResponse,
  IResponses,
} from '../../../../lib-ui/src/shared/interfaces/poll-responses';
import { IPartialUnit } from '../../../../lib-ui/src/shared/interfaces/unit';
import { PollService } from '../shared/services/poll.service';

@Component({
  selector: 'app-poll-web',
  standalone: true,
  imports: [CommonModule, PollManagerComponent, PageUnavailableComponent],
  templateUrl: './poll-web.component.html',
  styleUrls: ['./poll-web.component.scss'],
})
export class PollWebComponent implements OnInit, OnDestroy {
  answered = false;
  lockScreen = true;
  unitPoll!: IPartialUnit;
  interval!: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly pollService: PollService
  ) {}

  ngOnInit(): void {
    this.unitPoll = this.activatedRoute.snapshot.data['unitPoll'].data;
    this.checkAnswered();
    this.handlerSchedule();

    this.interval = setInterval(() => {
      this.checkAnswered();
      this.handlerSchedule();
    }, 300000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  async onFinishPoll(responses: IResponses[]): Promise<void> {
    const createdAt = new Date();

    const answers: IPollResponse = {
      id: '',
      branchId: this.unitPoll.id,
      pollId: this.unitPoll.poll!.id,
      deviceId: null,
      createdAt: createdAt.toISOString(),
      pollAnswers: responses,
    };

    const res = await lastValueFrom(
      this.pollService.sendPollResponse(answers).pipe(take(1))
    );

    if (res.data) {
      localStorage.setItem(
        'answered',
        new Date(createdAt.setMinutes(createdAt.getMinutes() + 15)).toString()
      );
      this.checkAnswered();
    }
  }

  checkAnswered(): void {
    const answered = localStorage.getItem('answered');

    if (answered) {
      const answeredDate = new Date(answered);

      if (new Date(answeredDate) > new Date()) {
        this.answered = true;
      } else {
        this.answered = false;
        localStorage.removeItem('answered');
      }
    } else {
      this.answered = false;
    }
  }

  handlerSchedule(): void {
    let lockScreenItem = true;

    this.unitPoll.availableShifts.forEach((shift) => {
      const now = new Date();

      const [initialHoras, initialMinutos] = shift.startTime
        .split(':')
        .map(Number);

      const [endHoras, endMinutos] = shift.endTime.split(':').map(Number);

      const start = new Date(
        new Date().setHours(initialHoras, initialMinutos, 0, 0)
      );
      const end = new Date(new Date().setHours(endHoras, endMinutos, 0, 0));

      if (end < start || end === start) {
        end.setDate(end.getDate() + 1);
      }

      if (now >= start && now <= end) {
        lockScreenItem = false;
      }
    });

    if (!this.unitPoll.availableShifts.length) {
      lockScreenItem = false;
    }

    this.lockScreen = lockScreenItem;
  }
}
