import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IPoll } from '../../../../../lib-ui/src/shared/interfaces/poll';
import { IPartialUnit } from '../../../../../lib-ui/src/shared/interfaces/unit';
import { environment } from '../../../environments/environment';
import { PollService } from './poll.service';

interface IResponse<T> {
  data: T | null;
  success: boolean;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class UnitPollService {
  private readonly URI_API = environment.URLAPI + 'Branch';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly pollService: PollService
  ) {}

  getUnitPoll(searchText: string): Observable<IResponse<IPartialUnit>> {
    return this.httpClient
      .get<IResponse<IPartialUnit>>(
        `${this.URI_API}/short-branch/${searchText}`
      )
      .pipe(
        switchMap(
          (
            res: IResponse<IPartialUnit>
          ): Observable<IResponse<IPartialUnit>> => {
            return res.success && res.data && res.data.pollId
              ? this.pollService
                  .getPollById(res.data.pollId)
                  .pipe(
                    map(
                      (
                        pollRes: IResponse<IPoll>
                      ): IResponse<IPartialUnit> => {
                        if (pollRes.success && pollRes.data) {
                          if (res.data) {
                            res.data.poll = pollRes.data;
                          }
                        } else {
                          if (res.data) {
                            res.data.poll = undefined;
                          }
                        }
                        return res;
                      }
                    ),
                    catchError((error) => {
                      console.error('Error fetching poll:', error);
                      return of({
                        data: null,
                        success: false,
                        message: 'Error fetching poll',
                      } as IResponse<IPartialUnit>);
                    })
                  )
              : of(res);
          }
        ),
        catchError((error) => {
          console.error('Error fetching unit poll:', error);
          return of({
            data: null,
            success: false,
            message: 'Error fetching unit poll',
          } as IResponse<IPartialUnit>);
        })
      );
  }
}
